<template>
  <div class="user">
    <div class="user_main">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="个人信息" name="first">
          <div class="first" v-if="firstShow">
            <el-form :model="ruleForm" :rules="rules" ref="upDataForm" label-width="100px" class="demo-ruleForm">
              <div class="head_c">
                <el-upload ref="zIcon" :show-file-list="false" name="upload" :action="imgAction()" :headers="Domainjwt"
                  :on-success="thumbnailSuccessiconUrl" :before-upload="field133BeforeUpload">
                  <div class="dis_flex">
                    <div class="avatar-uploader-sheji">
                      <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar" />
                      <div v-else class="icon">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                      </div>
                    </div>
                  </div>
                </el-upload>
              </div>
              <el-form-item label="账号：">{{ ruleForm.account }}</el-form-item>
              <el-form-item label="真实姓名：" prop="nickName ">
                <el-input v-model="ruleForm.nickName" placeholder="请输入您的真实姓名"></el-input>
              </el-form-item>
              <el-form-item label="手机号码：" prop="mobile">
                <el-input v-model="ruleForm.mobile" placeholder="请输入您的手机号码"></el-input>
              </el-form-item>
              <el-form-item label="性别：" prop="sex">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio label="男"></el-radio>
                  <el-radio label="女"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="生日：">
                <el-date-picker v-model="ruleForm.birthday" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                  placeholder="选择日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="个性签名：">
                <el-input type="textarea" :rows="5" :maxlength="20" show-word-limit
                  v-model="ruleForm.autograph"></el-input>
              </el-form-item>
              <div class="elbutton" @click="xiuGai">保存修改</div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane label="地址管理" name="second">
          <div class="second" v-if="secondShow">
            <div class="second_btn" icon="el-icon-plus" @click="addAddressClick">
              <i class="plus el-icon-plus"></i>
              新增地址
            </div>
            <div class="second_address">
              <div class="address_item" v-for="(item, index) in addressList" :key="index">
                <div class="item_img">
                  <el-image :src="require('@/assets/img/textmall/default_z.png')"
                    v-if="item.isDefault === 1"></el-image>
                </div>
                <div class="item_centent">
                  <div class="centent_name">
                    <div class="name" style="width: 200px;">
                      收货人：<span class="tip">{{ item.reveiveName }}</span>
                    </div>
                    <div class="name phone">
                      联系电话：<span class="tip">{{ item.mobile }}</span>
                    </div>
                  </div>
                  <div class="centent_bottom">
                    <div class="name">
                      收货地址：
                      <span class="tip">
                        {{ item.cityName + item.address }}
                      </span>
                    </div>
                    <div class="bottom_btn">
                      <div class="btn_defult" @click="defultClick(item)">
                        <el-image class="moren" :src="require('@/assets/img/textmall/moren_z.png')"
                          v-if="item.isDefault === 1"></el-image>
                        <div class="btn_circle" v-else></div>
                        <div :class="{
                          btn_title1: item.isDefault === 1,
                        }">
                          设为默认地址
                        </div>
                      </div>
                      <div class="btn_defult" @click="updateClick(item)">
                        <el-image class="edit" :src="require('@/assets/img/textmall/edit_z.png')"></el-image>
                        <div class="btn_title">编辑</div>
                      </div>
                      <div class="btn_defult" @click="deleteClick(item)">
                        <el-image class="edit" :src="require('@/assets/img/textmall/delete.png')"></el-image>
                        <div class="btn_title2">删除</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item_bottom">
                  <el-image :src="require('@/assets/img/textmall/line.png')"></el-image>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog class="addressDialog" center title="新增收货地址" :visible.sync="addressDialog" :close-on-click-modal="false"
      @close="cancelClick">
      <address-dialog v-if="addressDialog" :addressForm="addressForm" @save="save" @cancelClick="cancelClick" />
    </el-dialog>
  </div>
</template>
<script>
import { imagecode, selectCode } from "@/api/register";
import { pca, pcaa } from "area-data"; // 城市数据
import { getPcUser, updatePcUser, updatePcUserPassword } from "@/api/user";
import PhoneChange from "@/components/mine/phoneChange.vue";
import { setInfo, setToken, getInfo, getDomainToken } from "@/api/cookies";
import addressDialog from "@/views/seventhPage/mine/myPage/addressDialog.vue";
import {
  getUserAddress,
  delStuShopAddress,
  addStuShopAddress,
  updateStuShopAddress,
  setDefaultStuShopAddress,
} from "@/api/mykecheng";

export default {
  components: { PhoneChange, addressDialog },

  data() {
    let validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请确认您的新密码"));
      } else if (value !== this.form.passwords) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      addressDialog: false,
      addressForm: {},
      addressList: [],
      activeName: "first",
      firstShow: true,
      secondShow: false,
      userInfo: null,
      vmcity: [], // 地址
      pca: pca,
      pcaa: pcaa,
      name: "",
      names: "",
      Domainjwt: {
        Domainjwt: getDomainToken(),
      },
      imageUrl: "",
      user: "",
      tabIndex: 1,
      // 全部订单
      b: 1,
      captcha: "",
      ruleForm: {},
      detail: {
        img: "",
        nickName: "",
      },

      form: {
        password: "",
        passwords: "",
        imgCode: "",
      },

      rules: {
        nickName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入合法手机号",
            trigger: "blur",
          },
        ],
        gender: [
          { required: true, message: "性别不能为空", trigger: "change" },
        ],
        email: [
          { required: true, message: "电子邮箱不能为空", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入您现在的登录密码",
            trigger: "blur",
          },
          { min: 6, message: "密码长度不得小于6位数", trigger: "blur" },
        ],
        passwords: [
          { required: true, message: "请输入您的新密码", trigger: "blur" },
          { min: 6, message: "密码长度不得小于6位数", trigger: "blur" },
        ],
        passwordss: [
          { required: true, validator: validatePass2, trigger: "blur" },
          { min: 6, message: "密码长度不得小于6位数", trigger: "blur" },
        ],
        imgCode: [
          { required: true, message: "请输入右边验证码", trigger: "blur" },
        ],
      },
      type: {
        sjh: "sjh",
        qq: "qq",
        wx: "wx",
      },
      title: "修改绑定",
      titles: "已绑定",
      /* 修改绑定手机号 */
      dialogVisible: false,
      dialogVisibles: false,
      fileList: [
        {
          name: "默认头像.jpeg",
          url: "https://staticfile.xlcwx.com/avatar/icon1.png",
        },
      ],
      imgVal: 99,
      imgList: [
        "https://staticfile.xlcwx.com/avatar/icon0.png",
        "https://staticfile.xlcwx.com/avatar/icon1.png",
        "https://staticfile.xlcwx.com/avatar/icon2.png",
        "https://staticfile.xlcwx.com/avatar/icon3.png",
        "https://staticfile.xlcwx.com/avatar/icon4.png",
        "https://staticfile.xlcwx.com/avatar/icon5.png",
        "https://staticfile.xlcwx.com/avatar/icon6.png",
        "https://staticfile.xlcwx.com/avatar/icon7.png",
      ],
      add: "",
    };
  },
  async created() {
    this.userInfo = await getInfo();
    this.loadgetPcUser();
    this.loadCaptcha();
    if (!this.userInfo) {
      return;
    }
    if (this.userInfo.mobile) {
      this.title = "修改绑定";
      this.titles = "已绑定";
    } else {
      this.title = "立即绑定";
      this.titles = "未绑定";
    }
    this.getUserAddress();
    // 调用验证码接口方法

    // 调用基本资料接口方法
  },
  methods: {
    handleClick(tab) {
      if (tab.index === "0") {
        this.firstShow = true;
        this.secondShow = false;
      } else if (tab.index === "1") {
        this.firstShow = false;
        this.secondShow = true;
      }
    },
    // 获取地址
    async getUserAddress() {
      const res = await getUserAddress();
      if (res.code === 0) {
        this.addressList = res.msg;
      } else if (res.code === 500) {
        this.addressList = [];
      }
    },
    // 新增地址
    addAddressClick() {
      this.addressForm = {};
      this.addressDialog = true;
    },
    // 默认
    async defultClick(val) {
      const res = await setDefaultStuShopAddress({
        addressId: val.id,
        isDefault: Number(!val.isDefault),
      });
      if (res.code == 0) {
        this.getUserAddress();
      }
    },
    // 修改
    updateClick(val) {
      this.addressForm = val;
      this.add = val.address;

      this.addressDialog = true;
    },
    // 删除
    async deleteClick(val) {
      const res = await delStuShopAddress(val.id);
      if (res.code === 0) {
        this.getUserAddress();
      }
    },
    // 保存
    async save(val) {
      const params = Object.assign(val, { orderId: this.detail.id });
      if (val.id) {
        const res = await updateStuShopAddress(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.addressDialog = false;
        }
        this.getUserAddress();
      } else {
        const res = await addStuShopAddress(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.addressDialog = false;
        }
        this.getUserAddress();
      }
    },
    // 取消
    cancelClick() {
      this.addressList.forEach((item) => {
        if (item.id === this.addressForm.id) {
          item.address = this.add;
        }
      });
      this.addressForm = {};
      this.add = "";
      this.addressDialog = false;
    },
    // 固定图片选择
    imgClack(wx) {
      this.imgVal = wx;
    },
    // 调用验证码接口方法
    loadCaptcha() {
      imagecode().then((res) => {
        this.captcha = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        )}`;
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibles = true;
    },
    // 调用基本资料接口方法
    loadgetPcUser() {
      getPcUser(this.userInfo.id).then((res) => {
        localStorage.setItem("userId", res.msg.id);
        setInfo(res.msg);
        this.ruleForm = res.msg;
        this.vmcity = res.msg.provinceAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.provinceAddress)))
          : this.vmcity.concat([]);
        this.vmcity = res.msg.cityAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.cityAddress)))
          : this.vmcity.concat([]);
        this.vmcity = res.msg.areaAddress
          ? this.vmcity.concat(Object.keys(JSON.parse(res.msg.areaAddress)))
          : this.vmcity.concat([]);
        this.$forceUpdate();

        if (this.ruleForm.img != this.userInfo.img) {
          // 页面强制刷新
          window.location.reload();
        }
      });
      this.$forceUpdate();
    },
    // 基本资料 修改信息
    async xiuGai() {
      if (!this.ruleForm.nickName) {
        this.$message({
          message: "请输入姓名",
          type: "error",
        });
        return;
      }
      this.$refs.upDataForm.validate(async (valid) => {
        if (valid) {
          if (this.imgVal != 99) {
            this.ruleForm.img = this.imgList[this.imgVal];
          }
          updatePcUser(
            this.ruleForm.id,
            this.ruleForm.userName,
            this.ruleForm.nickName,
            this.ruleForm.mobile,
            this.ruleForm.sex,
            this.ruleForm.address,
            this.vmcity[0],
            this.ruleForm.img,
            this.vmcity[1],
            this.vmcity[2],
            this.ruleForm.email,
            this.ruleForm.birthday,
            this.ruleForm.autograph
          ).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              getPcUser(this.userInfo.id).then((res) => {
                localStorage.setItem("userId", res.msg.id);
                setInfo(res.msg);
              });

              // 页面强制刷新
              setTimeout(function () {
                // window.location.reload();
              }, 2000);
            }
          });
        }
      });
    },
    // 基本资料 修改密码
    xiuGaimima() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          if (this.form.passwords == this.form.passwordss) {
            selectCode(this.form.imgCode).then((res) => {
              if (res.code != 0) {
                this.$message.error("请输入正确的验证码");
                return;
              } else {
                updatePcUserPassword(
                  this.ruleForm.id,
                  this.form.password,
                  this.form.passwords,
                  this.form.imgCode
                ).then((res) => {
                  if (res.code == 0) {
                    this.form.password = "";
                    this.form.passwords = "";
                    this.form.imgCode = "";
                    this.loadCaptcha();
                    this.$message.success(res.msg);
                  } else {
                    this.$message.error(res.msg);
                  }
                });
              }
            });
          } else {
            this.$message.error("请输入相同的新密码");
          }
        }
      });
    },
    /* 标签页修改 */
    tabChange(val) {
      this.b = val;
    },
    // 文件上传成功时的钩子
    thumbnailSuccessiconUrl(res, file) {
      if (res.isSuccess) {
        this.ruleForm.img = res.path;
      } else {
        this.$message.error(res.error);
        Vue.prototype.imgClearn("zIcon", this);
      }
      this.$forceUpdate();
    },
    //文件上传大小校验
    field133BeforeUpload(file) {
      let isRightSize = file.size / 1024 / 1024 < 10;
      if (!isRightSize) {
        this.$message.error("文件大小超过 10MB");
      }
      return isRightSize;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__headerbtn {
  font-size: 22px !important;
}

.user {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;

  .user_main {
    /deep/ .el-tabs__nav-scroll {
      padding: 19px 40px 15px 32px;

      .el-tabs__item {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .is-active {
        color: #333333 !important;
      }

      .el-tabs__active-bar {
        width: 24px !important;
        height: 3px;
        background: #ff5d51;
        border-radius: 0px 0px 0px 0px;
        bottom: -14px;
        left: 20px;
        opacity: 1;
      }
    }

    /deep/ .el-tabs__nav-wrap::after {
      width: 93%;
      margin: 0 auto;
      margin-left: 30px;
      height: 1px;
      background-color: #eee;
    }

    /deep/ .el-tabs__content {
      padding: 11px 41px 25px;
    }

    .first {
      display: flex;
      justify-content: center;
      align-items: center;

      .demo-ruleForm {
        width: 500px;

        .head_c {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }

        /deep/ .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }

        .elbutton {
          width: 170px;
          height: 36px;
          margin-top: 40px;
          margin-left: 170px;
          background: #ff5e51;
          border-radius: 4px 4px 4px 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    .second {
      width: 100%;

      .second_btn {
        width: 110px;
        height: 36px;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #ff5d51;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff5d51;
        background-color: #fff;
        // z-index: 100000;
        cursor: pointer;

        .plus {
          margin-right: 5px;
          margin-bottom: -1px;
          font-size: 12px;
          font-weight: 600;
        }
      }

      .second_address {
        .address_item {
          margin-top: 20px;
          width: 100%;
          background: #ffffff;
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06);
          border-radius: 6px 6px 6px 6px;
          opacity: 1;
          position: relative;

          .item_img {
            width: 34px;
            height: 17px;
            position: absolute;
            top: 0;
            left: -2px;
          }

          .item_centent {
            padding: 0 30px 0 23px;

            .centent_name {
              padding-top: 26px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            .centent_bottom {
              padding: 10px 0 20px 0;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              .bottom_btn {
                display: flex;
                align-items: center;

                .btn_defult {
                  display: flex;
                  align-items: center;
                  margin-right: 30px;
                  font-size: 16px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  cursor: pointer;

                  .moren {
                    width: 20px;
                    height: 20px;
                    opacity: 1;
                    margin-right: 5px;
                  }

                  .btn_circle {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    opacity: 1;
                    border: 1px solid #999999;
                    margin-right: 5px;
                  }

                  .edit {
                    width: 13px;
                    height: 15px;
                    margin-right: 5px;
                  }
                }

                .btn_title1,
                .btn_title {
                  color: #ff5d51;
                }

                .btn_title2 {
                  color: #d81e06;
                }

                .btn_defult:nth-last-child(1) {
                  margin-right: 0px;
                }
              }
            }

            .name {
              width: 780px;
              font-size: 16px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #666666;

              .tip {
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
              }
            }

            .phone {
              margin-left: 100px;
            }
          }

          .item_bottom {
            position: absolute;
            width: 100%;
            height: 4px;
            bottom: 10px;

            /deep/ .el-image {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .addressDialog {
    /deep/ .el-dialog {
      width: 580px !important;
      background: #ffffff !important;
      border-radius: 12px !important;
      margin-top: 30vh !important;

      .el-dialog__title {
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      }
    }
  }
}
</style>
